import '@/styles/main.scss'

const addScript = (url, callback) => {
  const script = document.createElement('script')
  script.defer = true
  if (url) {
    script.src = url
  }

  if (callback) {
    script.onload = function() {
      callback()
    }
  }

  document.body.appendChild(script)
}

export const onClientEntry = () => {
  window.onload = () => {
    addScript(
      'https://booking-widget.phorestcdn.com/obwidget.bundle.js',
      initBooking,
    )
  }
}

// Site specific
function initBooking() {
  window.widget = new OBWidget('shihairdressing') // eslint-disable-line
}
